<template>
  <b-modal id="patient-canban-modal"
           :title="$t('patient')"
           hide-footer
           size="xl"
           centered>
    {{ patientCanban}}
    <div>{{ $t('status') }}: <span class="font-weight-bold" :style="`color: ${statusObj.color};`">{{ $t(statusObj.title) }}</span>
    </div>
    <div class="mb-3 overflow-scroll nowrap mt-3">
      <div class="d-flex flex-nowrap">
        <button v-for="l in funnelPatientStatuses"
                class="btn-themed btn-themed-outline btn-themed-squared mr-2 lead-status-btn"
                :style="`border-color: ${l.color}; color: ${l.color};`"
                @click="changeStatus(l.id)"
                :key="`le-${l.id}`">
          {{ $t(l.title) }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { funnelPatientStatuses } from "@/dictionaries/dictionary"
import {useStore} from "vuex"
import {computed} from "vue"
import {EloquentService} from "@/services/api.service"
import {appendFormdata, copyObject} from "@/extensions/prototypes/prototypes"

const store = useStore()

const patientCanban = computed(() => store.state.crm.patientCanban)
const statusObj = computed(() => funnelPatientStatuses.find(l => l.id === patientCanban.value.status))

const changeStatus = (status) => {
  saveCanban({ status })
}

const saveCanban = async (data) => {
  data.patient_id = patientCanban.value.patient_id
  const formData = appendFormdata(new FormData(), data)
  await EloquentService.update('patient_canban', patientCanban.value.id, formData, false, false)
  store.commit('incrementDataTableCounter')
  const updated = await EloquentService.show('patient_canban', patientCanban.value.id)
  store.commit('setPatientCanban', copyObject(updated.data))
}
</script>
